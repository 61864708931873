import React, { useEffect, useState, Fragment } from "react";
import { render } from "react-dom";
import FlipNumbers from "react-flip-numbers";
import moment from "moment";

import "./GoldCard.scss";
import { withTranslation } from "../../../i18n";

const GoldCard = ({ content }) => {

    const [count, setCount] = useState(false);
    const [number, setNumber] = useState("");

    const ScrollCenter = () => {
        if (!count) {
            let WindowHalfHeight = window.innerHeight / 2,
                scrollWrapper = document.querySelector(`.number`);
            window.addEventListener("scroll", () => {
                let ScrollTop = pageYOffset,
                    ScrollToHalfElementTop = WindowHalfHeight + ScrollTop,
                    ScrollToElementTop = scrollWrapper.getBoundingClientRect().top + ScrollTop;

                let timer;
                if (ScrollToHalfElementTop > ScrollToElementTop) {
                    timer = window.setTimeout(() => setCount(true), 4000);
                }
            });

        }
    };

    useEffect(() => {
        let targetNumber = document.querySelector(".hidden_number");

        if (targetNumber) {
            setCount(false);
            setNumber(targetNumber.innerText);
        }
    }, [content]);

    useEffect(() => {
        render(
            <div className={`number`}>
                <span>Выдано карт</span>
                <div className="wrapper_id">
                    <FlipNumbers
                        width={30}
                        height={40}
                        color="#A202C"
                        play perspective={100}
                        numbers={count
                            ? (+number + 1).toString()
                            : number
                        }
                    />
                    <p>
                        <hr/>
                        {moment().format("DD.MM.YYYY")}
                    </p>
                </div>
            </div>,
            document.getElementById("number"),
            () => ScrollCenter()
        );
    }, [count, number]);

    return null;
};


export default withTranslation("header")(GoldCard);

