import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../context/appState";
import { i18n } from "../i18n";
import TransitionedBlock from "../components/HelperComponents/TransitionedBlock/TransitionedBlock";
import "../components/PageBlocks/GoldCard/GoldCard.scss";
import GoldCard from "../components/PageBlocks/GoldCard/GoldCard";
import { useRouter } from "next/router";

const Info_block = (props) => {
    const router = useRouter();
    useEffect(() => {
        if (props?.err && props?.err?.statusCode === 404) {
            router.push('/404/')
        }
    }, []);
    if (props?.err && props?.err?.statusCode === 404) {
        return <></>
    }
    const { text, text_ua } = props.initialData;
    const { language } = i18n;
    const [content, setContent] = useState(null);


    useEffect(() => {
        language === "ru" ? setContent(text) : setContent(text_ua);
    }, [language]);

    const editText = () => {
        return { __html: content };
    };
    return content === null ? null : (
        <TransitionedBlock>
            <div className="info_block"
                dangerouslySetInnerHTML={editText()}
            />
            {props.initialData.slug === 'gold-page' && <GoldCard content={content} />}
        </TransitionedBlock>
    );
};

Info_block.getInitialProps = async function (context) {
    const { info_block } = context.query;
    let res = await fetch(`${API_BASE_URL}/section/${encodeURI(info_block)}/`);
    if (res.status === 404) {
        context.res.statusCode = 404
        return {
            namespacesRequired: ["services"],
            initialData: {},
            err: {
                statusCode: 404
            }
        }
    }
    const json = await res.json();
    const { language } = i18n;
    const { title, title_ua } = json;
    let metaTitle;
    language === "ua" ? metaTitle = title_ua : metaTitle = title;

    return {
        initialData: json,
        title: metaTitle,
        // description: json.description,
        keywords: "",
        pageClasses: "relative_wrapper",
        namespacesRequired: ["services"]
    };
};

export default Info_block;
